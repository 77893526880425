// Angular Directive for Typing Animation

import { Directive, ElementRef, Input, Renderer2, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[typingPlaceholder]',
  standalone:true
})
export class TypingPlaceholderDirective implements OnInit, OnDestroy {
  @Input('typingPlaceholder') placeholders: string[] = [];

  private isAnimationActive = false;
  private animationFrameId: number | null = null;
  private pauseTimeoutId: number | null = null;
  private placeholderIndex = 0;
  private charIndex = 0;
  private currentFullText = '';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.startTypingAnimation();
  }

  ngOnDestroy() {
    this.stopTypingAnimation();
  }

  private startTypingAnimation(): void {
    if (this.isAnimationActive) return;

    this.isAnimationActive = true;
    this.typePlaceholder();
  }

  private stopTypingAnimation(): void {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }

    if (this.pauseTimeoutId) {
      clearTimeout(this.pauseTimeoutId);
      this.pauseTimeoutId = null;
    }

    this.isAnimationActive = false;
    this.charIndex = 0;
    this.renderer.setAttribute(this.el.nativeElement, 'placeholder', '');
  }

  private typePlaceholder(): void {
    this.currentFullText = this.placeholders[this.placeholderIndex] || '';

    const typeWithRAF = () => {
      if (!this.isAnimationActive) return;

      if (this.charIndex <= this.currentFullText.length) {
        const currentPlaceholder = this.currentFullText.slice(0, this.charIndex);
        this.renderer.setAttribute(this.el.nativeElement, 'placeholder', currentPlaceholder);
        this.charIndex++;

        this.animationFrameId = requestAnimationFrame(typeWithRAF);
      } else {
        this.pauseTimeoutId = setTimeout(() => {
          this.moveToNextPlaceholder();
        }, 2000) as unknown as number;
      }
    };

    this.animationFrameId = requestAnimationFrame(typeWithRAF);
  }

  private moveToNextPlaceholder(): void {
    this.placeholderIndex = (this.placeholderIndex + 1) % this.placeholders.length;
    this.charIndex = 0;
    this.typePlaceholder();
  }
}
